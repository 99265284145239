// NPM
import React from 'react'

// CONTEXT
import CookiesProvider from './src/context/cookiesContext'
import NavigationProvider from './src/context/navigationContext'
import ExperienceProvider from './src/context/experienceContext'

// STYLES
import './src/styles/main.scss'

// TYPES
import type { GatsbyBrowser } from 'gatsby'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <CookiesProvider>
      <ExperienceProvider>
        <NavigationProvider>
          {element}
        </NavigationProvider>
      </ExperienceProvider>
    </CookiesProvider>
  )
}
