// NPM
import React, { useState, useRef, useEffect, useCallback, createContext, type ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

// TYPES
import type { NavigationContextProps } from '../types/context'

export const NavigationContext = createContext<NavigationContextProps>({
  bodyOverflow: false,
  isMenuOpen: false,
  isScrolled: false,
  isScrollingDown: false,
  toggleIsMenuOpenState: () => {},
  setIsMenuOpenState: () => {},
  setIsMenuClosedState: () => {},
  setBodyOverflowState: (canScroll: boolean) => {}
})

const NavigationProvider = function ({ children }: { children: ReactNode }): JSX.Element {
  const [bodyOverflow, setBodyOverflow] = useState<boolean>(true)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isScrollingDown, setIsScrollingDown] = useState<boolean>(false)
  const previousScrollPositionRef = useRef<number>(0)
  const scrolledThreshold = 60

  const toggleIsMenuOpenState = (): void => {
    setIsMenuOpen((prev) => !prev)
  }

  const setIsMenuOpenState = (): void => {
    setIsMenuOpen(true)
  }

  const setIsMenuClosedState = (): void => {
    setIsMenuOpen(false)
  }

  const setBodyOverflowState = (canScroll: boolean): void => {
    if (canScroll) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }

  const handleScroll = useCallback((): void => {
    const scrollTop = window.scrollY
    setIsScrolled(scrollTop > scrolledThreshold)
    setIsScrollingDown(scrollTop > previousScrollPositionRef.current)
    previousScrollPositionRef.current = scrollTop
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      setBodyOverflow(false)
    } else {
      setBodyOverflow(true)
    }
  }, [isMenuOpen])

  useEffect(() => {
    if (!bodyOverflow) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [bodyOverflow])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <NavigationContext.Provider value={{
      bodyOverflow,
      isMenuOpen,
      isScrolled,
      isScrollingDown,
      toggleIsMenuOpenState,
      setIsMenuOpenState,
      setIsMenuClosedState,
      setBodyOverflowState
    }}>
      <Helmet>
        <body className={classNames(
          { scrolled: isScrolled },
          { 'scrolling-down': isScrollingDown }
        )} />
      </Helmet>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
