// NPM
import React, { useState, useEffect, useRef, useCallback, createContext, type ReactNode } from 'react'

// TYPES
import type { ExperienceContextProps } from '../types/context'

export const ExperienceContext = createContext<ExperienceContextProps>({
  experienceStarted: false,
  introVideoCanBeRemoved: false,
  audioEnabled: false,
  isScrollCtaVisible: true,
  toggleExperienceStartedState: () => {},
  setExperienceStartedState: () => {},
  setExperienceClosedState: () => {},
  setIntroVideoCanBeRemovedState: (canBeRemoved: boolean) => {},
  toggleAudioEnabledState: () => {},
  setAudioEnabledState: () => {},
  setAudioDisabledState: () => {}
})

const ExperienceProvider = function ({ children }: { children: ReactNode }): JSX.Element {
  const [introVideoCanBeRemoved, setIntroVideoCanBeRemoved] = useState<boolean>(false)
  const [experienceStarted, setExperienceStarted] = useState<boolean>(false)
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false)
  const [isScrollCtaVisible, setIsScrollCtaVisible] = useState<boolean>(true)

  const scrollTimeout = useRef<NodeJS.Timeout | null>(null)

  const toggleExperienceStartedState = (): void => {
    setExperienceStarted(!experienceStarted)
    // setIntroVideoCanBeRemovedState(!introVideoCanBeRemoved)
  }

  const setExperienceStartedState = (): void => {
    setExperienceStarted(true)
    // setIntroVideoCanBeRemovedState(true)
  }

  const setExperienceClosedState = (): void => {
    setExperienceStarted(false)
    // setIntroVideoCanBeRemovedState(false)
  }

  const setIntroVideoCanBeRemovedState = (canBeRemoved: boolean): void => {
    setIntroVideoCanBeRemoved(canBeRemoved)
  }

  const toggleAudioEnabledState = (): void => {
    setAudioEnabled(!audioEnabled)
  }

  const setAudioEnabledState = (): void => {
    setAudioEnabled(true)
  }

  const setAudioDisabledState = (): void => {
    setAudioEnabled(false)
  }

  const handleScroll = useCallback((): void => {
    setIsScrollCtaVisible(false)

    if (scrollTimeout.current !== null) {
      clearTimeout(scrollTimeout.current)
    }

    scrollTimeout.current = setTimeout(() => {
      setIsScrollCtaVisible(true)
    }, 3000)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeout.current !== null) {
        clearTimeout(scrollTimeout.current)
      }
    }
  }, [handleScroll])

  return (
    <ExperienceContext.Provider value={{
      experienceStarted,
      introVideoCanBeRemoved,
      audioEnabled,
      isScrollCtaVisible,
      toggleExperienceStartedState,
      setExperienceStartedState,
      setExperienceClosedState,
      setIntroVideoCanBeRemovedState,
      toggleAudioEnabledState,
      setAudioEnabledState,
      setAudioDisabledState
    }}>
      {children}
    </ExperienceContext.Provider>
  )
}

export default ExperienceProvider
