// NPM
import React, { useEffect, createContext, type ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

// TYPES
import type { CookiesContextProps } from '../types/context'

export const CookiesContext = createContext<CookiesContextProps>({
  showCookiePolicy: () => {},
  openPreferences: () => {},
  showTcfVendors: () => {},
  resetCookies: () => {}
})

const CookiesProvider = function ({ children }: { children: ReactNode }): JSX.Element {
  const location = useLocation()

  const showCookiePolicy = (): void => {
    (window as any)._iub.cs.api.showCP()
  }

  const openPreferences = (): void => {
    (window as any)._iub.cs.api.openPreferences()
  }

  const showTcfVendors = (): void => {
    (window as any)._iub.cs.api.showTcfVendors()
  }

  const resetCookies = (): void => {
    (window as any)._iub.cs.api.resetCookies()
  }

  useEffect(() => {
    const preferencesLink = document.querySelector('.iubenda-cs-preferences-link')
    if (preferencesLink !== null) {
      preferencesLink.addEventListener('click', openPreferences)
    }
  }, [location.pathname])

  useEffect(() => {
    (window as any)._iub = (window as any)._iub || [];
    (window as any)._iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: true,
      countryDetection: true,
      enableFadp: true,
      enableLgpd: true,
      enableUspr: true,
      lgpdAppliesGlobally: false,
      perPurposeConsent: true,
      siteId: 3430867,
      cookiePolicyId: 68418870,
      lang: 'en-GB',
      cookiePolicyUrl: 'https://www.ilmagma.com/cookie-policy/',
      privacyPolicyUrl: 'https://www.ilmagma.com/privacy-policy/',
      privacyPolicyNoticeAtCollectionUrl: 'https://www.ilmagma.com/notice-at-collection/',
      banner: {
        acceptButtonCaptionColor: '#2B2B2B',
        acceptButtonColor: '#F5F3EC',
        acceptButtonDisplay: true,
        closeButtonDisplay: false,
        customizeButtonColor: '#333333',
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        linksColor: '#F5F3EC',
        position: 'float-bottom-center',
        rejectButtonCaptionColor: '#2B2B2B',
        rejectButtonColor: '#F5F3EC',
        rejectButtonDisplay: true,
        showTitle: false,
        textColor: '#F5F3EC'
      }
    }
  }, [])

  return (
    <CookiesContext.Provider value={{
      showCookiePolicy,
      openPreferences,
      showTcfVendors,
      resetCookies
    }}>
      <Helmet>
        <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3430867.js" />
        <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js" />
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          // eslint-disable-next-line react/no-unknown-property
          charSet="UTF-8"
        />
      </Helmet>

      {children}
    </CookiesContext.Provider>
  )
}

export default CookiesProvider
