exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-archive-clients-tsx": () => import("./../../../src/templates/archive-clients.tsx" /* webpackChunkName: "component---src-templates-archive-clients-tsx" */),
  "component---src-templates-archive-project-tags-tsx": () => import("./../../../src/templates/archive-project-tags.tsx" /* webpackChunkName: "component---src-templates-archive-project-tags-tsx" */),
  "component---src-templates-archive-project-tsx": () => import("./../../../src/templates/archive-project.tsx" /* webpackChunkName: "component---src-templates-archive-project-tsx" */),
  "component---src-templates-page-template-about-us-tsx": () => import("./../../../src/templates/page-template-about-us.tsx" /* webpackChunkName: "component---src-templates-page-template-about-us-tsx" */),
  "component---src-templates-page-template-coming-soon-tsx": () => import("./../../../src/templates/page-template-coming-soon.tsx" /* webpackChunkName: "component---src-templates-page-template-coming-soon-tsx" */),
  "component---src-templates-page-template-contacts-tsx": () => import("./../../../src/templates/page-template-contacts.tsx" /* webpackChunkName: "component---src-templates-page-template-contacts-tsx" */),
  "component---src-templates-page-template-cookie-policy-tsx": () => import("./../../../src/templates/page-template-cookie-policy.tsx" /* webpackChunkName: "component---src-templates-page-template-cookie-policy-tsx" */),
  "component---src-templates-page-template-home-page-tsx": () => import("./../../../src/templates/page-template-home-page.tsx" /* webpackChunkName: "component---src-templates-page-template-home-page-tsx" */),
  "component---src-templates-page-template-ilmagma-hotel-tsx": () => import("./../../../src/templates/page-template-ilmagma-hotel.tsx" /* webpackChunkName: "component---src-templates-page-template-ilmagma-hotel-tsx" */),
  "component---src-templates-page-template-nsl-subscribe-tsx": () => import("./../../../src/templates/page-template-nsl-subscribe.tsx" /* webpackChunkName: "component---src-templates-page-template-nsl-subscribe-tsx" */),
  "component---src-templates-page-template-privacy-policy-tsx": () => import("./../../../src/templates/page-template-privacy-policy.tsx" /* webpackChunkName: "component---src-templates-page-template-privacy-policy-tsx" */),
  "component---src-templates-single-client-tsx": () => import("./../../../src/templates/single-client.tsx" /* webpackChunkName: "component---src-templates-single-client-tsx" */),
  "component---src-templates-single-project-tsx": () => import("./../../../src/templates/single-project.tsx" /* webpackChunkName: "component---src-templates-single-project-tsx" */)
}

